import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w04ER06Katalanisch = () => (
  <Layout>
    <SEO title="w04ER06Katalanisch" />

    <h1 id="ueberschrift">Einfach (mal) Reden</h1>
    <h2>Katalanisch  &emsp;  ©&nbsp;2017</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w04ER06Katalanisch&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Meist ist es einfacher etwas zu lernen, wenn es mit anderen gemeinsam geübt werden kann.
        Aus diesem Grund bietet dieser Kurs die Möglichkeit
        bei einem gemütlichen Beisammensein das Gewünschte zu trainieren. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen
        oder einfach bei lustigen und interessanten Anekdoten in katalanischer Sprache die Zeit genießen.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        <b>Muttersprachler sind herzlich willkommen!</b><br />
        <b>Els parlants nadius són benvinguts!</b>
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        Voraussetzungen: + Grundkenntnisse der katalanischen Sprache +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w04ER06Katalanisch
